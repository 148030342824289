import images from "../images";

export const PHOTO_GALLERY_IMAGES = [
  images.img1,
  images.img2,
  images.img3,
  images.img4,
  images.img5,
  images.img6,
  images.img7,
  images.img8,
  images.img9,
  images.img10,
];

export const RENTAL_IMAGES = [
  images.rentalVideo,
  images.rentalImg1,
  images.rentalImg2,
  images.rentalImg3,
];
